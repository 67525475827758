import { Dialog } from "@mui/material";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import Multiselect from "../../Multiselect/Multiselect";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import "./filtersModal.css";
import Button from "../../Button/Button";
import {
  getModelFilters,
  getShowroomFilters
} from "../../../redux-sagas/actions";

const FiltersModal = ({ open, toggleModal, queryParams, setQueryParams }) => {
  const dispatch = useDispatch();
  const models = useSelector((state) => state.modelFilters);
  const showrooms = useSelector((state) => state.showroomFilters);
  const [values, setValues] = useState({
    models: [],
    showrooms: []
  });

  const { t } = useTranslation();
  const closeModal = () => toggleModal("filters");
  const handleOpenModal = (type) => {
    const { brands } = queryParams;

    dispatch(
      type === "showrooms"
        ? getShowroomFilters("?skip=0&take=&brands=" + brands)
        : getModelFilters("?skip=0&take=&brands=" + brands)
    );
  };

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleAccept = () => {
    setQueryParams({
      ...queryParams,
      models: values.models.length > 0 ? values.models.join(",") : "",
      showrooms: values.showrooms.length > 0 ? values.showrooms.join(",") : ""
    });
    closeModal();
  };

  return (
    <Dialog className="filters-modal" open={open}>
      <div className="modal-header">
        <span>{getTranslation("Filters", t)}</span>
        <span onClick={closeModal}>
          <MdClose />
        </span>
      </div>
      <div className="modal-types">
        <Multiselect
          data={showrooms.data}
          valueField="showroomId"
          label="Branch"
          name="showrooms"
          handleChange={handleChange}
          onOpen={() => handleOpenModal("showrooms")}
          textField="showroomName"
          value={values.showrooms}
        />
        <Multiselect
          data={models.data}
          valueField="modelId"
          label="Models"
          name="models"
          handleChange={handleChange}
          onOpen={() => handleOpenModal("models")}
          textField="modelName"
          value={values.models}
        />
      </div>
      <div className="btn-group">
        <Button handleClick={closeModal} text="Cancel" type="secondary" />
        <Button handleClick={handleAccept} text="Accept" type="primary" />
      </div>
    </Dialog>
  );
};

export default FiltersModal;
