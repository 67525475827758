import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from "@mui/material";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import "./multiselect.css";

const Multiselect = ({
  value,
  data,
  label,
  handleChange,
  valueField,
  textField,
  name,
  onOpen
}) => {
  const { t } = useTranslation();

  return (
    <FormControl className="multiselect" sx={{ m: 1, width: 300 }}>
      <InputLabel id="demo-multiple-checkbox-label">
        {getTranslation(label, t)}
      </InputLabel>
      <Select
        name={name}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        handleChange={handleChange}
        onOpen={onOpen}
        onChange={handleChange}
        renderValue={(selected) => {
          const names = data
            .filter((item) => selected.includes(item[valueField]))
            .map((item) => item[textField]);
          return names.join(", ");
        }}
      >
        {data?.map((item) => (
          <MenuItem key={item[valueField]} value={item[valueField]}>
            <Checkbox checked={value.indexOf(item[valueField]) > -1} />
            <ListItemText primary={item[textField]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Multiselect;
