import { useEffect, useState } from "react";
import ScheduleFilters from "./ScheduleFilters/ScheduleFilters";
import ScheduleContent from "./ScheduleContent/ScheduleContent";
import { formatDate } from "../../utils/formatDateTime";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleList } from "../../redux-sagas/actions/index";
import "./scheduleTestDrive.css";
import { format, addDays } from "date-fns";
import getBrands from "../../utils/getBrands";
import { getVehiclesParams } from "../../utils/getParams";

const ScheduleTestDrive = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const minimumBookingDays = settings?.brandGroupConfiguration?.minimumBookingDays ?? 0;
  const defaultDate = addDays(new Date(), minimumBookingDays);
  const toReschedule = useSelector((state) => state.toReschedule);
  const [queryParams, setQueryParams] = useState({
    take: 3,
    date: toReschedule
      ? formatDate(
        new Date(toReschedule.bookingDate) <= defaultDate
          ? defaultDate
          : new Date(toReschedule.bookingDate)
      )
      : formatDate(defaultDate),
    showrooms: "",
    models: "",
    orderBy: "",
    vehicleId: toReschedule ? toReschedule.vehicleId : "",
    clientDateTime: format(new Date(), "yyyy-MM-dd HH:mm"),
    brands: getBrands()
  });

  useEffect(() => {
    const getData = setTimeout(() => dispatch(getVehicleList(getVehiclesParams(queryParams))), 10);

    return () => clearTimeout(getData);
  }, [queryParams]);

  return (
    <div className='schedule-cont'>
      <ScheduleFilters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <ScheduleContent
        defaultDate={defaultDate}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
    </div>
  );
};

export default ScheduleTestDrive;
