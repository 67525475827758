import { Dialog } from "@mui/material";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import "./orderByModal.css";

const OrderByModal = ({ open, toggleModal, handleChangeOrder }) => {
  const { t } = useTranslation();

  const handleClose = () => toggleModal("orderBy");
  const handleChange = (value) => {
    handleChangeOrder({ target: { value } });
    handleClose();
  };

  return (
    <Dialog className="orderBy-modal" open={open}>
      <div className="modal-header">
        <span>{getTranslation("Order by", t)}</span>
        <span onClick={handleClose}>
          <MdClose />
        </span>
      </div>
      <div className="modal-types">
        <span onClick={() => handleChange("vehicleName")}>
          {getTranslation("Car", t)}
        </span>
        <span onClick={() => handleChange("showroomName")}>
          {getTranslation("Branch", t)}
        </span>
      </div>
    </Dialog>
  );
};

export default OrderByModal;
