import React from "react";
import "./muiDatePicker.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import * as locales from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const MuiDatePicker = ({ value, handleChange, defaultDate }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={locales[i18n.language]}
      >
        <DatePicker
          className="date-picker"
          minDate={defaultDate}
          slots={{
            openPickerIcon: KeyboardArrowDownIcon
          }}
          format="MMMM - yyyy"
          value={new Date(value)}
          slotProps={{
            textField: { readOnly: true }
          }}
          onChange={(newDate) => handleChange(newDate, "calendar")}
        />
      </LocalizationProvider>
    </>
  );
};

export default MuiDatePicker;
