import { useState, useEffect } from "react";
import AppliedFilter from "../../../components/AppliedFilter/AppliedFilter";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FormGroup } from "react-bootstrap";
import { Checkbox, Collapse, FormControlLabel } from "@mui/material";
import "./scheduleFilters.css";
import { useDispatch, useSelector } from "react-redux";
import { getModelFilters, getShowroomFilters } from "../../../redux-sagas/actions";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const ScheduleFilters = ({ queryParams, setQueryParams }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const models = useSelector((state) => state.modelFilters);
	const showrooms = useSelector((state) => state.showroomFilters);
	const [applied, setApplied] = useState({
		models: [],
		showrooms: []
	});

	const [isExpanded, setIsExpanded] = useState({
		models: true,
		showrooms: true,
		modelsMore: false,
		showroomsMore: false
	});

	useEffect(() => {
		const { brands } = queryParams;
		dispatch(getShowroomFilters("?skip=0&take=4&brands=" + brands));
		dispatch(getModelFilters("?skip=0&take=4&brands=" + brands));
	}, []);

	const toggleShow = (container) =>
		setIsExpanded({ ...isExpanded, [container]: !isExpanded[container] });

	const handleChangeSelect = (type, current) => {
		const isSame = (item) =>
			(item.modelName ?? item.showroomName) === (current.modelName ?? current.showroomName);

		// If current item is already in models/showrooms array,
		// remove from array.
		// If not, add to array
		if (applied[type].find((item) => isSame(item))) {
			setApplied({
				...applied,
				[type]: applied[type].filter((item) => !isSame(item))
			});
		} else {
			setApplied({
				...applied,
				[type]: applied[type].concat(current)
			});
		}
	};

	useEffect(() => {
		const modelIds = applied.models.map((item) => item.category ? `${item.category}|${item.modelId}` : item.modelId);
		const showroomIds = applied.showrooms.map((item) => item.showroomId);
		setQueryParams({
			...queryParams,
			models: modelIds.length > 0 ? modelIds.join(",") : "",
			showrooms: showroomIds.length > 0 ? showroomIds.join(",") : ""
		});
	}, [applied]);

	const toggleShowMoreLess = (type) => {
		const { brands } = queryParams;
		const dataName = type.split("More")[0];
		// show less
		if (isExpanded[type])
			dispatch(
				dataName === "showrooms"
					? getShowroomFilters("?skip=0&take=4&brands=" + brands)
					: getModelFilters("?skip=0&take=4&brands=" + brands)
			);
		// show more
		else
			dispatch(
				dataName === "showrooms"
					? getShowroomFilters(`?skip=0&take=${showrooms.totalRecords}&brands=${brands}`)
					: getModelFilters(`?skip=0&take=${models.totalRecords}&brands=${brands}`)
			);

		setIsExpanded({ ...isExpanded, [type]: !isExpanded[type] });
	};

	return (
		<div className='filters'>
			<h5>{getTranslation("Filters", t)}</h5>
			<div className='applied'>
				{applied.models.concat(applied.showrooms).map((item, idx) => {
					return (
						<AppliedFilter
							key={idx}
							name={item.modelName ? "models" : "showrooms"}
							current={item}
							removeFilter={handleChangeSelect}
						>
							{item.modelName ? `${item.modelName} (${item.count})` : item.showroomName}
						</AppliedFilter>
					);
				})}
			</div>
			<div className='models'>
				<div
					className='heading'
					onClick={() => toggleShow("models")}
				>
					<p>{getTranslation("Models", t)}</p>
					{isExpanded.models ? <AiOutlineMinus /> : <AiOutlinePlus />}
				</div>
				<Collapse
					in={isExpanded.models}
					timeout='auto'
					unmountOnExit
				>
					<FormGroup>
						{models.data.map((model, idx) => (
							<FormControlLabel
								key={`${model.modelId};${idx}`}
								control={
									<Checkbox
										disableRipple
										checked={
											applied.models.find((item) => item.modelName === model.modelName)
												? true
												: false
										}
										onChange={(e) => handleChangeSelect("models", model)}
									/>
								}
								label={`${model.modelName} (${model.count})`}
							/>
						))}
					</FormGroup>
					{models.totalRecords > 4 && (
						<p
							className='see-more'
							onClick={() => toggleShowMoreLess("modelsMore")}
						>
							{isExpanded.modelsMore && models.data.length > 4
								? getTranslation("Show less", t)
								: getTranslation("Show more", t)}
						</p>
					)}
				</Collapse>
			</div>
			<div className='showrooms'>
				<div
					className='heading'
					onClick={() => toggleShow("showrooms")}
				>
					<p>{getTranslation("Branch", t)}</p>
					{isExpanded.showrooms ? <AiOutlineMinus /> : <AiOutlinePlus />}
				</div>
				<Collapse
					in={isExpanded.showrooms}
					timeout='auto'
					unmountOnExit
				>
					<FormGroup>
						{showrooms.data.map((showroom) => (
							<FormControlLabel
								key={showroom.showroomId}
								control={
									<Checkbox
										disableRipple
										checked={
											applied.showrooms.find((item) => item.showroomName === showroom.showroomName)
												? true
												: false
										}
										onChange={(e) => handleChangeSelect("showrooms", showroom)}
									/>
								}
								label={showroom.showroomName}
							/>
						))}
					</FormGroup>
					{showrooms.totalRecords > 4 && (
						<p
							className='see-more'
							onClick={() => toggleShowMoreLess("showroomsMore")}
						>
							{isExpanded.showroomsMore && showrooms.data.length > 4
								? getTranslation("Show less", t)
								: getTranslation("Show more", t)}
						</p>
					)}
				</Collapse>
			</div>
		</div>
	);
};

export default ScheduleFilters;
