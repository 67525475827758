import { useState } from "react";
import FunctionalityCol from "./FunctionalityCol/FunctionalityCol";
import TextCol from "./TextCol/TextCol";
import "./bookingTable.css";
import { BsCalendarWeek } from "react-icons/bs";
import { FaWindowClose } from "react-icons/fa";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";
import { useDispatch } from "react-redux";
import {
  getTestDrives,
  setLoading,
  toggleNotification,
  setToReschedule,
  setCurrentPage
} from "../../redux-sagas/actions";
import fetchClient from "../../services/fetch";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { formatCurrentDate } from "../../utils/formatDateTime";
import { format } from "date-fns";
import getUtmParams from "../../utils/getUtmParams";
import { getMyTestDriveParams } from "../../utils/getParams";

const BookingTable = ({ booking, payload }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showCancelModal, setShowCancelModal] = useState(false);

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };

  const toggleAcceptModal = () => {
    setShowCancelModal(false);
    dispatch(setLoading(true));
    fetchClient()
      .put(`/testdrives/${booking.bookingId}/status/cancel`, getUtmParams().payload)
      .then(() => {
        dispatch(setLoading(false));
        dispatch(
          toggleNotification({
            isOpen: true,
            type: "success",
            content1: getTranslation("The", t),
            highlight1: "Test Drive",
            content2: getTranslation("has been successfully canceled.", t),
            highlight2: ""
          })
        );
        dispatch(getTestDrives(getMyTestDriveParams(payload)));
      })
      .catch((err) => console.log(err));
  };

  const handleRescheduleClick = () => {
    dispatch(setToReschedule(booking));
    dispatch(setCurrentPage("schedule"));
  };

  return (
    <div className='booking'>
      <img
        src={booking.vehicleImageUrl}
        alt={`${booking.vehicle} icon`}
      />
      <div className='table'>
        <TextCol
          colName='Branch'
          data={booking.showroomName}
        />
        <TextCol
          colName='Date'
          data={format(new Date(booking.bookingDate), "dd/MM/yyyy")}
        />
        <TextCol
          colName='Start Time'
          data={booking.startTimeFormatted}
        />
        <TextCol
          colName='End Time'
          data={booking.endTimeFormatted}
        />
        <TextCol
          colName='Model'
          data={booking.modelName}
        />
        <TextCol
          colName='Variant'
          data={booking.variantName}
        />
        <FunctionalityCol
          disabled={formatCurrentDate(new Date()) > formatCurrentDate(booking.bookingDateFormatted)}
          className='reschedule'
          handleClick={handleRescheduleClick}
          text='Reschedule appointment'
        >
          <BsCalendarWeek />
        </FunctionalityCol>
        <FunctionalityCol
          disabled={formatCurrentDate(new Date()) > formatCurrentDate(booking.bookingDateFormatted)}
          className='cancel'
          text='Cancel appointment'
          handleClick={toggleCancelModal}
        >
          <FaWindowClose />
        </FunctionalityCol>
        <ConfirmationModal
          handleAccept={toggleAcceptModal}
          handleBack={toggleCancelModal}
          open={showCancelModal}
          text={getTranslation("Are you sure about canceling your Test Drive?", t)}
          title={getTranslation("Cancel Test Drive", t)}
        />
      </div>
    </div>
  );
};

export default BookingTable;
